import { PureAbility } from '@casl/ability';
import { useSDK } from '@connect-core/connect-sdk';

/**
 * hook gets the ability for the user
 */
export const useAbility = () => {
    const { users } = useSDK();
    const { data, isLoading } = users.useUser();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ability = new PureAbility((data?.rules as any) ?? []);

    return { ability, isLoading };
};
