'use client';

import { Flex } from '@chakra-ui/react';
import { ConnectAiLogo } from '@common/Logo';
import { useAbility } from '@common/hooks/useAbility';
import { ROUTES } from '@root/app/routes';
import { NavButton } from '@routes/_components/NavButton';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { usePathname } from 'next/navigation';
import { GoGear } from 'react-icons/go';
import { LuCopyCheck } from 'react-icons/lu';
import {
    MdCalendarViewMonth,
    MdCalendarViewWeek,
    MdEngineering,
    MdGridOn,
    MdOutlineAddToPhotos,
    MdOutlineDashboard,
    MdOutlineFileCopy,
    MdOutlineHandshake,
    MdOutlineInterests,
    MdOutlinePeople,
    MdSettings,
} from 'react-icons/md';
import { NavFolder } from './NavFolder';
import { NavItem } from './NavItem';

export function SidebarContent({ expanded = false }) {
    const flags = useFlags();

    const showTpm = flags['tradeModule'] ?? false;
    const showDemand = flags['demandModule'] ?? false;
    const pathname = usePathname();
    const pathSegments = pathname.split('/').filter(Boolean);
    const mainPath = '/' + pathSegments[0];
    const childPath = '/' + pathSegments[0] + '/' + pathSegments[1];
    const absolutePath = '/' + pathSegments.join('/');

    const { ability } = useAbility();

    return (
        <Flex
            h="full"
            direction="column"
            px={1}
            gap={8}
            alignContent="center"
            justifyContent="center"
            w={expanded ? 'var(--sidebar-width)' : 'var(--sidebar-width-not-expanded)'}
            color="{colors.nav.bar.fg}"
        >
            <ConnectAiLogo showText={expanded} backgroundColor="black" fill="#FAFAFA" />
            <Flex direction="column" flex="1" overflow="auto" gap={1}>
                <NavItem
                    icon={<MdOutlineDashboard title="Dashboard" size={24} />}
                    label="Dashboard"
                    href={ROUTES.DASHBOARD.INDEX}
                    tooltip={!expanded && 'Dashboard'}
                    showLabel={expanded}
                    active={mainPath === ROUTES.DASHBOARD.INDEX}
                    activeColor="{colors.nav.active.parent}"
                />
                <NavFolder
                    label="Documents"
                    icon={<MdOutlineFileCopy title="Documents" size={24} />}
                    tooltip={!expanded && 'Documents'}
                    href={ROUTES.TEMPLATES.GENERATE_NEW}
                    hinted={mainPath === ROUTES.TEMPLATES.ROOT}
                    active={childPath === ROUTES.TEMPLATES.GENERATE_NEW}
                >
                    <NavItem
                        icon={<MdOutlineAddToPhotos title="Create New" size={24} />}
                        label="Create New"
                        href={ROUTES.TEMPLATES.GENERATE_NEW}
                        active={childPath === ROUTES.TEMPLATES.GENERATE_NEW}
                    />
                    {ability.can('manage', 'UploadedDocument') && (
                        <NavItem
                            icon={<MdOutlineFileCopy title="Templates" size={24} />}
                            label="Templates"
                            href={ROUTES.TEMPLATES.CUSTOMER}
                            active={childPath === ROUTES.TEMPLATES.CUSTOMER}
                        />
                    )}
                </NavFolder>
                {ability.can('read', 'Customer') && (
                    <NavItem
                        icon={<MdOutlinePeople title="Customers" size={24} />}
                        label="Customers"
                        href={ROUTES.MASTER_DATA.CUSTOMERS}
                        tooltip={!expanded && 'Customers'}
                        showLabel={expanded}
                        active={mainPath === ROUTES.MASTER_DATA.CUSTOMERS}
                        activeColor="{colors.nav.active.parent}"
                    />
                )}
                <NavFolder
                    label="Products"
                    icon={<LuCopyCheck title="Products" size={24} />}
                    tooltip={!expanded && 'Products'}
                    href={ROUTES.MASTER_DATA.FINISHED_GOODS}
                    active={absolutePath === ROUTES.MASTER_DATA.FINISHED_GOODS}
                    hinted={mainPath === ROUTES.MASTER_DATA.PRODUCTS}
                >
                    {ability.can('update', 'Product') && (
                        <NavItem
                            icon={<MdOutlineInterests title="Trade Item Management" size={24} />}
                            label="Trade Item Management"
                            href={ROUTES.MASTER_DATA.PRODUCTS}
                            tooltip={!expanded && 'Trade Item Management'}
                            active={absolutePath === ROUTES.MASTER_DATA.PRODUCTS}
                        />
                    )}
                    {ability.can('manage', 'Brand') && (
                        <NavItem
                            icon={<GoGear title="Brand Management" size={24} />}
                            label="Brand Management"
                            href={ROUTES.MASTER_DATA.BRAND_MANAGEMENT}
                            tooltip={!expanded && 'Brand Management'}
                            active={absolutePath === ROUTES.MASTER_DATA.BRAND_MANAGEMENT}
                        />
                    )}
                </NavFolder>
                {showTpm && (
                    <NavFolder
                        label="Trade"
                        icon={<MdOutlineHandshake title="Trade" size={24} />}
                        tooltip={!expanded && 'Trade planning'}
                        href={ROUTES.TRADE_PLANNING.TRADE_PLANS}
                        hinted={mainPath === ROUTES.TRADE_PLANNING.PLANS}
                        active={childPath === ROUTES.TRADE_PLANNING.TRADE_PLANS}
                    >
                        <NavItem
                            icon={<MdOutlineAddToPhotos title="Create New" size={24} />}
                            label="Create new"
                            href={ROUTES.TRADE_PLANNING.CREATE}
                            active={childPath === ROUTES.TRADE_PLANNING.CREATE}
                        />
                        <NavItem
                            icon={<MdCalendarViewWeek title="Calendar" size={24} />}
                            label="Calendar"
                            href={ROUTES.TRADE_PLANNING.CALENDAR}
                            active={childPath === ROUTES.TRADE_PLANNING.CALENDAR}
                        />
                        <NavItem
                            icon={<MdCalendarViewMonth title="Trade Plans" size={24} />}
                            label="Trade plans"
                            href={ROUTES.TRADE_PLANNING.TRADE_PLANS}
                            active={childPath === ROUTES.TRADE_PLANNING.TRADE_PLANS}
                        />
                        <NavItem
                            icon={<MdEngineering title="Management" size={24} />}
                            label="Management"
                            href={ROUTES.TRADE_PLANNING.BUDGETS}
                            active={childPath === ROUTES.TRADE_PLANNING.BUDGETS}
                        />
                    </NavFolder>
                )}
                {showDemand && ability.can('read', 'DemandPlan') && (
                    <NavFolder
                        label="Demand"
                        icon={<MdGridOn title="Demand" size={24} />}
                        tooltip={!expanded && 'Demand planning'}
                        href={ROUTES.DEMAND_PLANNING.CURRENT_FORECAST.ROOT}
                        hinted={mainPath === ROUTES.DEMAND_PLANNING.ROOT}
                        active={childPath === ROUTES.DEMAND_PLANNING.CURRENT_FORECAST.ROOT}
                    >
                        <NavItem
                            icon={<MdOutlineAddToPhotos title="Create New" size={24} />}
                            label="Create new"
                            href={ROUTES.DEMAND_PLANNING.CREATE_NEW}
                            active={childPath === ROUTES.DEMAND_PLANNING.CREATE_NEW}
                        />
                        <NavItem
                            icon={<MdCalendarViewWeek title="Current" size={24} />}
                            label="Current"
                            href={ROUTES.DEMAND_PLANNING.CURRENT_FORECAST.ROOT}
                            active={childPath === ROUTES.DEMAND_PLANNING.CURRENT_FORECAST.ROOT}
                        />
                        <NavItem
                            icon={<MdCalendarViewMonth title="Drafts" size={24} />}
                            label="All Plans"
                            href={ROUTES.DEMAND_PLANNING.PLANS.ROOT}
                            active={childPath === ROUTES.DEMAND_PLANNING.PLANS.ROOT}
                        />
                        {ability.can('manage', 'ForecastMethodology') && (
                            <NavItem
                                icon={<MdSettings title="Forecast Methodology" size={24} />}
                                label="Forecast"
                                href={ROUTES.DEMAND_PLANNING.ADMIN.ROOT}
                                active={childPath === ROUTES.DEMAND_PLANNING.ADMIN.ROOT}
                            />
                        )}
                    </NavFolder>
                )}
            </Flex>
            <NavButton expanded={expanded} />
        </Flex>
    );
}
