import { Tooltip } from '@/components/ui/tooltip';
import { Box, Flex, IconButton, Link, useDisclosure, VStack } from '@chakra-ui/react';
import { useSidebarContext } from '@common/context/sidebar-context';
import NextLink from 'next/link';
import React from 'react';
import { FaChevronDown as ChevronDownIcon, FaChevronUp as ChevronUpIcon } from 'react-icons/fa';
import { NavItem } from './NavItem';

export type NavFolderProps = {
    icon: React.ReactElement;
    label: string;
    href?: string;
    defaultIsOpen?: boolean;
    hoverColor?: string;
    activeColor?: string;
    hintedColor?: string;
    tooltip?: React.ReactNode;
    active: boolean;
    hinted: boolean;
};

export function NavFolder({
    icon,
    label,
    href,
    children,
    defaultIsOpen = false,
    hoverColor = '{colors.nav.hover.parent}',
    activeColor = '{colors.nav.active.parent}',
    hintedColor = '{colors.nav.hinted.parent}',
    tooltip,
    active,
    hinted,
}: React.PropsWithChildren<NavFolderProps>) {
    const { open, onToggle } = useDisclosure({ defaultOpen: defaultIsOpen });
    const [hovered, setHovered] = React.useState(false);
    const [chevronHovered, setChevronHovered] = React.useState(false);
    const { isSidebarExpanded } = useSidebarContext();

    const isHighlighted = active || hinted;
    React.useEffect(() => {
        /* Justification for useEffect:
         * We want the user to be able to override this by clicking the chevron,
         * but also want to override *their* selection when the page changes.
         * This component is mounted only once, so we cannot rely on a default.
         * Therefore we have to look for a prop change and cause a side effect */
        if (open !== isHighlighted) {
            onToggle();
        }
    }, [isHighlighted]);

    const handleMouseEnter = (_event: React.MouseEvent) => {
        if (isSidebarExpanded) {
            return;
        }

        setHovered(true);
    };

    const handleMouseLeave = (event: React.MouseEvent) => {
        if (isSidebarExpanded) {
            return;
        }

        const boundingRect = event.currentTarget.getBoundingClientRect();
        const mouseX = event.clientX;
        const mouseY = event.clientY;
        const leftBoundary = boundingRect.left + 2;
        const topBoundary = boundingRect.top + 2;
        const bottomBoundary = boundingRect.bottom - 2;

        if (mouseY <= topBoundary || mouseY >= bottomBoundary || mouseX <= leftBoundary) {
            setHovered(false);
        }
    };

    const hasItemChildren = React.useMemo(() => {
        return (children instanceof Array ? children : [children]).some((child) => child.type == NavItem);
    }, [children]);

    return (
        <Link
            as={NextLink}
            w="full"
            href={href || '#'}
            color={`{${active || hinted ? 'white' : 'colors.nav.bar.fg'}}`}
            _hover={{ color: 'white' }}
            cursor="pointer"
            style={{
                display: 'block',
                width: '100%',
                textDecoration: 'none',
                border: 'unset',
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ['--focus-ring-color' as any]: '#00000000',
            }}
        >
            <Flex direction="column" w="full">
                <Flex
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    w="full"
                    px={2}
                    minHeight="2.75rem"
                    userSelect="none"
                    rounded="md"
                    transition="all 0.2s"
                    _hover={{ bg: active || hinted || chevronHovered ? undefined : hoverColor, color: 'white' }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    bgColor={active ? activeColor : hinted ? hintedColor : undefined}
                    color={`{${active || hinted ? 'white' : 'colors.nav.bar.fg'}}`}
                >
                    <Flex gap="1" alignItems="center">
                        <Flex gap="1" alignItems="center">
                            <Box px="1">
                                <Tooltip openDelay={0} content={tooltip} disabled={!tooltip} showArrow={true}>
                                    {icon}
                                </Tooltip>
                            </Box>
                            {isSidebarExpanded && label}
                        </Flex>
                        {!isSidebarExpanded && (
                            <>
                                <Box hidden={!hovered} position="absolute" left="100%" color="{colors.nav.bar.bg}">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="32"
                                        className="bi bi-google"
                                        viewBox="0 0 16 16"
                                    >
                                        <path
                                            d="M8 16L8 0L0.707106 7.29289C0.316582 7.68342 0.316582 8.31658 0.707106 8.70711L8 16Z"
                                            fill="currentColor"
                                        />
                                    </svg>
                                </Box>
                                <VStack
                                    hidden={!hovered}
                                    bg="{colors.nav.bar.bg}"
                                    direction="column"
                                    left="114%"
                                    position="absolute"
                                    width="40"
                                    minW="fit-content"
                                    p="4"
                                    borderTopRightRadius={8}
                                    borderBottomRightRadius={8}
                                    zIndex="popover"
                                    onMouseLeave={() => {
                                        setHovered(false);
                                    }}
                                >
                                    {children}
                                </VStack>
                            </>
                        )}
                    </Flex>
                    <IconButton
                        {...(!isSidebarExpanded || !hasItemChildren ? { display: 'none' } : {})}
                        bg={chevronHovered ? 'gray.700' : 'transparent'}
                        border="none"
                        color="currentColor"
                        size="xs"
                        transition="backgrounds"
                        onClick={(ev) => {
                            ev.stopPropagation();
                            ev.preventDefault();
                            onToggle();
                        }}
                        onMouseEnter={() => setChevronHovered(true)}
                        onFocus={() => setChevronHovered(true)}
                        onMouseLeave={() => setChevronHovered(false)}
                        onBlur={() => setChevronHovered(false)}
                        cursor="pointer"
                        aria-label={open ? 'close' : 'open'}
                    >
                        {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
                    </IconButton>
                </Flex>
                {isSidebarExpanded && (
                    <VStack w="full" pl={4} pt={1} gap={1}>
                        {open && children}
                    </VStack>
                )}
            </Flex>
        </Link>
    );
}
