'use client';

import { Tooltip } from '@/components/ui/tooltip';
import { Box, Flex, HStack } from '@chakra-ui/react';
import { cx } from '@common/utils';
import { useRouter } from 'next/navigation';
import { BsCaretRightFill } from 'react-icons/bs';
import { NavItemProps } from './TopNavigation';

export const NavItem = ({
    active,
    subtle,
    icon,
    children,
    label,
    endElement,
    href,
    hoverColor = '{colors.nav.hover.child}',
    activeColor = '{colors.nav.active.child}',
    tooltip,
    showLabel = true,
}: React.PropsWithChildren<NavItemProps>) => {
    const router = useRouter();

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        if (href) {
            router.push(href);
        }
    };

    return (
        <Flex
            as="div"
            w="full"
            color={`{${active ? 'white' : 'colors.nav.bar.fg'}}`}
            _hover={{ color: 'white' }}
            style={{
                display: 'block',
                width: '100%',
                textDecoration: 'none',
                border: 'unset',
                ['--focus-ring-color' as any]: '#00000000',
            }}
            onClick={(e) => handleClick(e)}
            cursor="pointer"
        >
            <HStack
                w="full"
                minHeight="2.75rem"
                fontSize="sm"
                cursor="pointer"
                userSelect="none"
                rounded="md"
                transition="all 0.2s"
                bgColor={active ? activeColor : undefined}
                _hover={{ bg: active ? undefined : hoverColor }}
                _focus={{ outline: 'none' }}
            >
                <Box pl={3} fontSize="lg">
                    <Tooltip openDelay={0} content={tooltip} disabled={!tooltip} showArrow={true}>
                        {icon}
                    </Tooltip>
                </Box>
                {showLabel && label && (
                    <Box flex="1" pr={2} fontWeight="500" color={cx(subtle && 'gray.400')}>
                        {label}
                    </Box>
                )}
                {endElement && !children && <Box>{endElement}</Box>}
                {children && <Box fontSize="xs" flexShrink={0} as={BsCaretRightFill} />}
            </HStack>
        </Flex>
    );
};
