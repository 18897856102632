'use client';

import {
    DrawerBackdrop,
    DrawerBody,
    DrawerCloseTrigger,
    DrawerContent,
    DrawerHeader,
    DrawerRoot,
    DrawerTitle,
} from '@/components/ui/drawer';
import { DrawerFooter, Flex, IconButton } from '@chakra-ui/react';
import React from 'react';
import { MdArrowBack, MdArrowForward, MdNotifications } from 'react-icons/md';
import { useOnClickOutside } from 'usehooks-ts';
import { useNotificationContext } from './NotificationContext';
import { NotificationItem } from './NotificationItem';

export function NotificationDrawer() {
    const {
        isOpen,
        isLoading,
        notifications,
        actions: { close, nextPage, prevPage },
    } = useNotificationContext();

    const ref = React.useRef<any>(null);

    useOnClickOutside(ref, () => {
        close();
    });

    if (isLoading) {
        <div>Loading...</div>;
    }

    return (
        <>
            <DrawerRoot open={isOpen} size="md" onEscapeKeyDown={() => close()}>
                <DrawerBackdrop />

                <DrawerContent ref={ref} data-testid="notification-drawer">
                    <DrawerCloseTrigger onClick={() => close()} />
                    <DrawerHeader>
                        <DrawerTitle>
                            <Flex gap={2} alignItems="center">
                                <MdNotifications /> Notifications
                            </Flex>
                        </DrawerTitle>
                    </DrawerHeader>
                    <DrawerBody>
                        {!notifications?.items?.length ? <Flex justifyContent="center">No notifications</Flex> : null}

                        {
                            <Flex direction="column" gap={4}>
                                {notifications?.items?.map((notification) => (
                                    <NotificationItem key={notification.id} notification={notification} />
                                ))}
                            </Flex>
                        }
                    </DrawerBody>
                    <DrawerFooter>
                        <Flex justifyContent="space-between">
                            {notifications?.hasNextPage && (
                                <IconButton onClick={() => prevPage()}>
                                    <MdArrowBack />
                                </IconButton>
                            )}

                            {notifications?.hasNextPage && (
                                <IconButton onClick={() => nextPage()}>
                                    <MdArrowForward />
                                </IconButton>
                            )}
                        </Flex>
                    </DrawerFooter>
                </DrawerContent>
            </DrawerRoot>
        </>
    );
}
